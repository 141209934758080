var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","max-width":"700px","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","title":"Edit Device"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Edit Device MSRP - "+_vm._s(_vm.equipList[0].model))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.saveData}},[_vm._v(" Save & Close ")])],1)],1),_c('v-card-text',[_c('TotalValuations',{attrs:{"removeUnit":false}}),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"space-around"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"show-expand":"","items":_vm.equipList,"expanded":_vm.expanded,"hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.desc",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.desc))])]}},{key:"item.msrp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.msrp))+" ")]}},{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")((item.msrp * item.quantity)))+" ")]}},{key:"item.include",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"color":item.include ? 'success' : 'error'},model:{value:(item.include),callback:function ($$v) {_vm.$set(item, "include", $$v)},expression:"item.include"}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pt-2 pb-2",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":headers,"items":item.accessories,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.desc",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"280px"}},[_vm._v(_vm._s(item.desc))])]}},{key:"item.msrp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.msrp))+" ")]}},{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")((item.msrp * item.quantity)))+" ")]}},{key:"item.include",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"color":item.include ? 'success' : 'error'},model:{value:(item.include),callback:function ($$v) {_vm.$set(item, "include", $$v)},expression:"item.include"}})]}}],null,true)})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }