<template>

        <v-row justify="space-around" class="mt-5 mb-3">
          <v-data-table
            :headers="headers"
            :items="sv[meterType]"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:item.startMonth="{ item }">
                  {{ item.startMonth }}-{{ item.endMonth }}
            </template>
            <template v-slot:item.totalServicePayment="{ item }">
                {{ item.totalServicePayment | currency }}
            </template>
            <template v-slot:item.overageRate="{ item }">
                {{ item.overageRate |  currency('$', 6 )}}
            </template>
            <template v-slot:item.startDate="{ item }">
                  {{ formatDate(item.startDate) }}
            </template>
          </v-data-table>
        </v-row>

</template>

<script>
import { mapState } from 'vuex'
import { format } from "date-fns";

export default {
 
props: {
    meterType: {
        type: String,
        required: true,
        default: "mono"
    }
},
data() {
    return {
      rateList: [],
      headers: [
        {
          text: 'Year',
          align: 'center',
          sortable: false,
          value: 'year',
        },
        {
          text: 'Months',
          align: 'end',
          sortable: false,
          value: 'startMonth',
        },
        {
          text: 'Payment',
          align: 'end',
          sortable: false,
          value: 'totalServicePayment',
        },
        {
          text: 'Overage',
          align: 'end',
          sortable: false,
          value: 'overageRate',
        },
        {
          text: 'Start Date',
          align: 'center',
          sortable: false,
          value: 'startDate',
        },
        {
          text: 'Total Payment',
          align: 'end',
          sortable: false,
          value: 'totalServicePayment',
        }


      ],
      rules: {
        required: (value) => !!value || 'This field is required.',
        minRate: (value) =>
          (!!value && value > 0) || 'Value must be greater than zero.',
        overageRate: (value) =>
          (!!value && /^\d+\.\d{1,6}$/.test(value)) ||
          'Enter valid rate (Ex. 0.0097)',
        dollarValue: (value) =>
          (!!value &&
            /^(?!0|\.00)[0-9]+(,\d{3})*?(\.[0-9][0-9]?)?$/.test(value)) ||
          'Invalid Amount',
      },
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return format(new Date(date), "MM/dd/yyyy");
    }
  },
  computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues,
      sv: (state) => state.maint
    }),
  }

}
</script>

<style>
.v-data-table-header th[role=columnheader] {
  font-size: 18px !important;
}

.v-data-table td {
    font-size: 16px !important;
}

.v-data-table tr:nth-child(even) {
  background: rgb(230, 239, 245);
}
</style>