import readXlsxFile from 'read-excel-file'
import { v4 as uuidv4 } from 'uuid'

export default {
    
    async organizeEquipData(equipWs) {

        if(!equipWs) return null
        let totalMainframes = 0
        let totalDelivery = 0 
        let totalInstall = 0        
        let totalCost = 0
        let totalBuyout = 0
        let totalMSRP = 0
        let totalReturns = 0
        let equipVersion = ""
        let equipmentList = []

        equipVersion = equipWs[2][1];
        
        equipWs.map((row) => {

            let feeFlag = false

            const material = row[5] ? row[5].toString() : null;
            const lineTotal = row[3] * row[9]

            //Delivery Fee
            if (material && material.includes("Delivery")) {
                totalDelivery = totalDelivery + lineTotal
                feeFlag = true
            }

            //Installation Fee
            if (material && material.includes("Installation")) {
                totalInstall = totalInstall + lineTotal                
                feeFlag = true
            }

            //Buyout
            if (material && material.includes("SF_LEASE_BUYOUT")) {
                totalBuyout = totalBuyout + lineTotal
                feeFlag = true
            }

            //Return 
            if (material && material.includes("Equipment Return")) {
                totalReturns = totalReturns + lineTotal
                feeFlag = true
            }

            if (!feeFlag && row[2] === true) {
                totalMainframes = totalMainframes + row[3]

                let equip = {}
                equip.id = uuidv4()
                equip.model = row[4]
                equip.product = row[5]
                equip.desc = row[6]
                equip.quantity = row[3]
                equip.msrp = row[7]
                equip.outcost = row[9]
                equip.custtotal = row[11]
                equip.bwbase = row[13]
                equip.bwstdrate = row[14]
                equip.bwbaseovr = row[15]
                equip.bwoverage = row[16]
                equip.bwoverageovr = row[17] //BW Override Overage Rate
                equip.colorbase = row[18]
                equip.colorstdrate = row[19]
                equip.colorbaseovr = row[20]
                equip.coloroverage = row[21]
                equip.coloroverageovr = row[22]
                equip.startMeter = 25;
                equip.make = "";
                equip.serial = "";
                equip.valuation = 0;
                equip.valPercent = 0;
                equip.include = true;
                equip.accessories = []

                equipmentList.push(equip)
            }

            if (!feeFlag && row[2] === false) {

                let accessory = {}
                accessory.id = uuidv4()
                accessory.equipId = equipmentList.at(-1).id
                accessory.model = row[4]
                accessory.product = row[5]
                accessory.desc = row[6]
                accessory.quantity = row[3]
                accessory.msrp = row[7]
                accessory.outcost = row[9]
                accessory.custtotal = row[11]
                accessory.include = true

                // Find equipment ID and push to accessory list
                // equipmentList.find(equip => equip.id === unit.id).accessories.push(accessory)
                equipmentList.at(-1).accessories.push(accessory)

            }

        })

        // Add up MSRP and Costs for Equipment
        equipmentList.map((unit) => {
            // Calculate Per Unit Totals
            let unitCost = 0;
            let unitMSRP = 0;
            unitCost = unit.quantity * unit.outcost
            unitMSRP = unit.quantity * unit.msrp

            // Calculate Order Total
            totalCost = totalCost + unitCost
            totalMSRP = totalMSRP + unitMSRP

            unit.accessories.map((acc) => {
                if(acc.include) {
                    let accTotalCost = acc.quantity * acc.outcost
                    let accTotalMSRP = acc.quantity * acc.msrp
    
                    unitCost = unitCost + accTotalCost
                    unitMSRP = unitMSRP + accTotalMSRP
                    totalCost = totalCost + accTotalCost
                    totalMSRP = totalMSRP + accTotalMSRP
                }

            })

            // Record each total unit outcost and MSRP
            equipmentList.at(-1).unitTotalOutCost = unitCost
            equipmentList.at(-1).unitTotalMSRP = unitMSRP

        })

        // Calculate Unit Total Cost Percentage for Valuations
        equipmentList.map((unit) => {
            let unitPercent = 0;
            unitPercent = (Math.round((unit.unitTotalOutCost / totalCost) * 100) / 100)
            equipmentList.at(-1).valPercent = unitPercent
        })

        const equipData = {
            totalMainframes,
            totalMSRP,
            totalCost,
            totalBuyout,
            totalDelivery,
            totalInstall,
            totalReturns,
            equipVersion,
            equipment: [...equipmentList]
        }

        return equipData

    },
    // async organizecheckData(checkData) {
    //     return checkData;

    // },
    async organizeOrderData(odData) {
        // Order Data - from Order Details spreadsheet

        if(!odData) return null

        let quoteNumber = '';
        let quoteStatus = '';
        let quoteCreated = '';
        let quoteLastMod = '';
        let salesPro = '';
        let salesCode = '';
        let sapId = '';
        let companyName = '';
        let saleType = '';
        let dealType = '';
        let leaseRateAndOptions = '';
        let leaseTerm = 0;
        let leaseTotalPayment = 0;
        let leaseEquipmentPayment = 0;
        let totalServicePayment = 0;
        let totalBWPayment = 0;
        let totalColorPayment = 0;
        let totalBWPages = 0;
        let blendedBWRate = 0;
        let totalColorPages = 0;
        let blendedColorRate = 0;
        let baseFreq = {};
        let meterFreq = {};
        let odVersion = ''; // 76 8

        odVersion = odData[76][8]
        quoteNumber = odData[7][3]
        quoteStatus = odData[7][4]
        quoteCreated = odData[6][3]
        quoteLastMod = odData[5][3]
        salesPro = odData[8][3]
        salesCode = odData[9][3]
        sapId = odData[10][3]
        companyName = odData[16][3]
        saleType = odData[28][3]
        dealType = odData[29][3]
        leaseRateAndOptions = odData[29][7]
        leaseTerm = odData[30][7]
        leaseEquipmentPayment = odData[32][7]
        leaseTotalPayment = odData[33][7]
        totalServicePayment = odData[39][7]
        totalBWPayment = odData[40][7]
        totalColorPayment = odData[41][7]
        totalBWPages = odData[42][7]
        blendedBWRate = odData[42][8]
        totalColorPages = odData[43][7]
        blendedColorRate = odData[43][8]
        baseFreq = { cycle: odData[44][7], months: this.getCycleMonths(odData[44][7]) }
        meterFreq = { cycle: odData[45][7], months: this.getCycleMonths(odData[45][7])  }

        const orderDetails = {
            odVersion,
            quoteNumber,
            quoteStatus,
            quoteCreated,
            quoteLastMod,
            salesPro,
            salesCode,
            sapId,
            companyName,
            saleType,
            dealType,
            leaseRateAndOptions,
            leaseTerm,
            leaseEquipmentPayment,
            leaseTotalPayment,
            totalServicePayment,
            totalBWPayment,
            totalColorPayment,
            totalBWPages,
            blendedBWRate,
            totalColorPages,
            blendedColorRate,
            baseFreq,
            meterFreq
        }

        return orderDetails

    },
    async getWorkbook(workbookFile) {

        let equipData, odData = null;

        const equipWs = await readXlsxFile(workbookFile, { sheet: 'Internal EQUIPMENT DETAILS' })
        //const checkWs = await readXlsxFile(workbookFile, { sheet: 'Internal CHECKLIST'})
        const odWs = await readXlsxFile(workbookFile, { sheet: 'Internal ORDER DETAILS'})

        equipData = await this.organizeEquipData(equipWs)
        //checkData = await this.organizecheckData(checkWs)
        odData = await this.organizeOrderData(odWs) 

        return { equipData, odData}

    },
    getCycleMonths(cycle) {

        if(cycle === 'Monthly') {
            return 1
        }
        if(cycle === 'Quarterly') {
            return 3
        }
        if(cycle === 'Semi-Annual') {
            return 6
        }
        if(cycle === 'Annual') {
            return 12
        } else {
            return 1
        }

    }
}