import store from '@/store/index.js'

export default () => {

    let equipmentList = store.state.equipment.eqData.equipment
    const totalFunding = store.state.computed.computedValues.totalFunding

    let totalValuation = 0;
    let totalValPer = 0;
    let totalCost = 0;
    let totalMSRP = 0;
    let unitCost = 0;
    let unitMSRP = 0;

    // Add up MSRP and Costs for Equipment
    equipmentList.forEach((unit) => {

        unitCost = 0;
        unitMSRP = 0;

        if (unit.include) {
            // Calculate Per Unit Totals
            unitCost = unit.quantity * unit.outcost
            unitMSRP = unit.quantity * unit.msrp

            // Calculate Order Total
            totalCost = totalCost + unitCost
            totalMSRP = totalMSRP + unitMSRP
        }

        unit.accessories.map((acc) => {
            if(acc.include) {
                let accTotalCost = acc.quantity * acc.outcost
                let accTotalMSRP = acc.quantity * acc.msrp

                unitCost = unitCost + accTotalCost
                unitMSRP = unitMSRP + accTotalMSRP
                totalCost = totalCost + accTotalCost
                totalMSRP = totalMSRP + accTotalMSRP
            }

        })

        // Record each total unit outcost and MSRP
        unit.unitTotalOutCost = unitCost
        unit.unitTotalMSRP = unitMSRP
    })

    // Calculate Unit Total Cost Percentage for Valuations
    equipmentList.forEach((unit) => {

       let unitPercent = 0;
       let unitValuation = 0;

        unitPercent = (Math.round((unit.unitTotalOutCost / totalCost) * 100) / 100)
        unitValuation = (Math.round((unitPercent * totalFunding ) * 100) / 100 )

        unit.valPercent = (Math.round(unitPercent * 100) / 100)
        unit.valuation = unitValuation

        totalValPer = totalValPer + unit.valPercent
        totalValuation = totalValuation + unitValuation

    })

    // Reconcile totalFunding vs. totalValuation 
    if (totalValuation.toFixed(2) !== totalFunding.toFixed(2)) {

        let totalDifference = totalValuation - totalFunding
        let totalValPerDifference = totalValPer - 1  

        if (totalDifference > 0) {
            //Valuation Larger
            equipmentList.forEach((unit) => {
                if (totalValuation.toFixed(2) !== totalFunding.toFixed(2)) {
                    if(unit.include) {
                        unit.valuation = unit.valuation - totalDifference
                        totalValuation = totalValuation - totalDifference
                        unit.valPercent = unit.valPercent - totalValPerDifference                         
                        totalValPer = totalValPer - totalValPerDifference
                    } 
                }
            })
        } else {
            //Valuation Smaller
            equipmentList.forEach((unit) => {
                if (totalValuation.toFixed(2) !== totalFunding.toFixed(2)) {
                    if(unit.include) {
                        unit.valuation = unit.valuation - totalDifference
                        totalValuation = totalValuation - totalDifference
                        unit.valPercent = unit.valPercent - totalValPerDifference                        
                        totalValPer = totalValPer - totalValPerDifference
                    } 
                }
            })
        }    
    }

    // Calculate difference needed to get to 125% of MSRP
    let addToMSRP = null;
    if ((totalFunding / totalMSRP) > 1.25) {
        
        let amountNeeded = totalFunding / 1.25

        addToMSRP = amountNeeded - totalMSRP

        
    } else {
        addToMSRP = 0
    }

    totalValuation = parseFloat(totalValuation)

    store.dispatch('computed/setcomputedValues', { ...store.state.computed.computedValues, 
        totalValPer, totalValuation, addToMSRP })

    store.dispatch('equipment/setEqData', { ...store.state.equipment.eqData, 
        totalMSRP, totalCost, equipment: equipmentList })
}