<template>
  <v-row justify="space-around" class="mt-1">
    <v-col
      cols="4"
      sm="4"
      md="3"
      lg="2"
      xl="2"
      :class="removeUnit ? 'text-end d-none d-lg-inline' : 'text-end' "
    >
      <v-chip color="primary" outlined>
        <h3>
          TOTALS
        </h3>
      </v-chip>
    </v-col>
    <v-col
      cols="4"
      sm="4"
      md="3"
      lg="2"
      xl="2"
      :class="removeUnit ? 'text-end d-none d-lg-inline' : 'text-end' "
    >
      <v-chip color="primary" outlined>
        <h3>
          {{ equipData.totalMSRP | currency }}
        </h3>
      </v-chip>
    </v-col>
    <v-col
      cols="4"
      sm="4"
      md="3"
      lg="2"
      xl="2"
      :class="removeUnit ? 'text-end d-none d-lg-inline' : 'text-end' "
    >
      <v-chip color="primary" outlined>
        <h3>
          {{ (cv.totalFunding / equipData.totalMSRP) | percent }}
        </h3>
      </v-chip>
    </v-col>
    <v-col cols="7" sm="4" md="3" lg="2" xl="2" class="text-end">
      <v-chip
        :color="
          cv.totalValuation.toFixed(2) === cv.totalFunding.toFixed(2)
            ? 'success'
            : 'error'
        "
        outlined
      >
        <h3>
          {{ cv.totalValuation | currency }}
        </h3>
      </v-chip>
    </v-col>
    <v-col cols="5" sm="4" md="3" lg="2" xl="2" class="text-end">
      <v-chip :color="cv.totalValPer === 1 ? 'success' : 'error'" outlined>
        <h3>
          {{ cv.totalValPer | percent }}
        </h3>
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
props: {
    removeUnit: {
        type: Boolean,
        required: true,
        default: true
    }
},
computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues,
    }),
  }
}
</script>