<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    max-width="700px"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" v-bind="attrs" v-on="on" title="Edit Device">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <!-- <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
        <v-toolbar-title
          >Edit Device MSRP - {{ equipList[0].model }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-btn dark text @click="recalc">
            Recalculate
          </v-btn> -->

          <v-btn dark text @click="saveData">
            Save &amp; Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <TotalValuations :removeUnit="false" />
        <v-row justify="space-around" class="mt-5">
          <v-data-table
            :headers="headers"
            show-expand
            :items="equipList"
            :expanded.sync="expanded"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:item.desc="{ item }">
              <div class="text-truncate">{{ item.desc }}</div>
            </template>
            <template v-slot:item.msrp="{ item }">
              {{ item.msrp | currency }}
            </template>
            <template v-slot:item.serial="{ item }">
              {{ (item.msrp * item.quantity) | currency }}
            </template>
            <template v-slot:item.include="{ item }">
              <v-simple-checkbox
                v-model="item.include"
                :ripple="false"
                :color="item.include ? 'success' : 'error'"
              ></v-simple-checkbox>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-2 pb-2">
                <v-data-table
                  :headers="headers"
                  :items="item.accessories"
                  hide-default-header
                  hide-default-footer
                >
                  <template v-slot:item.desc="{ item }">
                    <td class="text-truncate" style="max-width: 280px;">{{ item.desc }}</td>
                  </template>
                  <template v-slot:item.msrp="{ item }">
                    {{ item.msrp | currency }}
                  </template>
                  <template v-slot:item.serial="{ item }">
                    {{ (item.msrp * item.quantity) | currency }}
                  </template>
                  <template v-slot:item.include="{ item }">
                    <v-simple-checkbox
                      v-model="item.include"
                      :ripple="false"
                      :color="item.include ? 'success' : 'error'"
                    ></v-simple-checkbox>
                  </template>
              </v-data-table>
              </td>
              </template>
          </v-data-table>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TotalValuations from '@/components/fds/fdsparts/financials/TotalValuations.vue';
import getValuations from '@/components/fds/getValuations.js';

export default {
  props: {
    equipId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      expanded: [],
      equipList: [],
      dialog: false,
      headers: [
        {
          text: 'Model',
          align: 'start',
          sortable: false,
          value: 'model',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'desc',
          width: '50px'
        },
        {
          text: 'Qty',
          align: 'start',
          sortable: false,
          value: 'quantity',
        },
        {
          text: 'Unit MSRP',
          align: 'end',
          sortable: false,
          value: 'msrp',
        },
        {
          text: 'Total MSRP',
          align: 'end',
          sortable: false,
          value: 'serial',
        },
        {
          text: 'Include',
          align: 'center',
          sortable: false,
          value: 'include',
        },
      ],
    };
  },
  methods: {
    ...mapActions('equipment', ['updateEquip']),    
    saveData() {
      this.updateEquip(this.equipList[0]);
      getValuations();
      this.dialog = false;      
    }
  },
  components: {
    TotalValuations
  },
  computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues,
    }),
  },
  created() {
    let equip = this.equipData.equipment.find((unit) => this.equipId === unit.id);
    this.equipList.push(equip)
    this.expanded = this.equipList
  }
};
</script>