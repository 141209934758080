<template>
    <v-container>
      <v-card outlined class="flex-grow-1 flex-shrink-0 align-self-start ma-2" 
              style="max-width: 500px" 
              v-if="odData === null"
              >
        <v-card-title>
          Upload Financials
        </v-card-title>
        <v-card-text>
          <VueDropZone 
                ref="finSheet"
                id="finSheet"
                :options="getDropZoneOptions"
                :useCustomSlot="true"
                class="mt-10"
                v-on:vdropzone-files-added="filesAdded"
                v-on:vdropzone-removed-file="fileRemoved"
            >
             <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    Drag and Drop Financials
                  </h3>
                  <div class="subtitle">
                    ...or click to select a file from your computer
                  </div>
                </div>
            </VueDropZone> 

            <v-btn outlined class="mt-5 mb-5" color="success" @click="getFinSheet" :disabled="fileCount == 0">
                <v-icon>mdi-check</v-icon> Process File
            </v-btn>
        </v-card-text>
      </v-card>
      <v-container v-if="odData !== null">
        <v-layout column>
        <v-row>
        <v-col cols="12" >
          <DemoGraphic />
        </v-col>
        <v-col cols="12" v-if="cv.leaseRate !== null">
          <Financials />
        </v-col>
        </v-row>
        </v-layout>
      </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    </v-container>
    
</template>
<script>
// import { downloadZip } from 'client-zip/index.js';
// import { saveAs } from 'file-saver';
import { mapState, mapActions } from "vuex";
import fds from '@/components/fds/processFinancials.js'
import DemoGraphic from '@/components/fds/fdsparts/demographic/DemoGraphic.vue'
import Financials from '@/components/fds/fdsparts/financials/Financials.vue'
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  metaInfo: {
    title: "Funding Detail Sheet"
  },
    components: {      
      DemoGraphic,
      Financials,
      VueDropZone: vue2Dropzone
  },
  data() {
    return {
      finSheet: null,
      fileList: null,
      fileCount: 0,
      wb: {},
      overlay: false,
      showInvalidFileError: false,
      processingDataFiles: false
    } 
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("equipment", [ "setEqData" ]),
    ...mapActions("orderdetails", [ "setOdData" ]),
    ...mapActions("computed", [ "setcomputedValues"]),
    async getFinSheet() {
       // Get Financial Spreadsheet
       this.finSheet = this.fileList[0]
        if (this.finSheet != null) { 
            this.overlay = true;
            try {
              this.wb = await fds.getWorkbook(this.finSheet);
              this.setEqData(this.wb.equipData)
              this.setOdData(this.wb.odData)
              this.overlay = false;
            } catch {
              this.finSheet = null;
              this.overlay = false;
              this.showSnack({
                timeout: 5000,
                text: "Unable to Read Uploaded File...",
                color: "error"
              })
            }
        } else {
          this.finSheet = null;
          this.showSnack({
            timeout: 5000,
            text: "File Input Error - Please try again.",
            color: "error"
          })
        }
    },
    filesAdded() {
      setTimeout(() => {
              this.fileList = this.$refs.finSheet.getQueuedFiles();
              this.fileCount = this.fileList.length;
            }, 500);
    },
    fileRemoved() {
      this.fileCount = this.fileCount - 1
    }
  },
  computed: {
      getDropZoneOptions() {
      return {
        url: 'http://error.nolink',
        maxFileSize: 1.0,
        maxFiles: 1,
        addRemoveLinks: true,
        autoProcessQueue: false,
        acceptedFiles: 'application/excel, .xlsx'
      };
    },
    ...mapState({
      equipData: state => state.equipment.equipData,
      odData: state => state.orderdetails.odData,
      cv: state => state.computed.computedValues
    })
  }
}
</script>