<template>
  <v-container>
    <v-row justify="space-around" align="stretch">
      <v-col cols="12" sm="12" md="8" lg="4" xl="3" class="d-flex flex-column">
        <v-card class="px-4 flex d-flex flex-column" outlined>
          <v-row class="justify-center align-center mt-1 mb-1">
            <v-icon class="mx-2">mdi-account-cash-outline</v-icon>
            <h3 class="text-center py-2">FUNDING</h3>
          </v-row>
          <v-row class="justify-end align-center mx-1 my-1">
            <h4 class="mr-2">Total:</h4>
            <v-chip color="primary" outlined>
              <h3>
                {{ cv.totalFunding | currency }}
              </h3>
            </v-chip>
          </v-row>
          <!-- <v-row class="justify-end mx-1 my-1"> -->
          <v-row class="justify-end align-center mx-1 my-1">
            <h4 class="mr-2"><span class="d-none d-xl-inline">Less </span>Buyout:</h4>
            <v-chip color="warning" outlined>
              <h3>
                {{ equipData.totalBuyout | currency }}
              </h3>
            </v-chip>
          </v-row>
          <v-row class="justify-end align-center mx-1 my-1">
            <h4 class="mr-2">Net:</h4>
            <v-chip :color="(cv.totalFunding - equipData.totalBuyout) > 0 ? 'success' : 'error' ">
              <h3>
                {{
                  (cv.totalFunding - equipData.totalBuyout) | currency
                }}
              </h3>
            </v-chip>
          </v-row>
        </v-card>
      </v-col>

      <!-- Payment Info -->
      <v-col  cols="12" sm="12" md="8" lg="4" xl="3" class="d-flex flex-column">
        <v-card class="px-4 flex d-flex flex-column" outlined>
          <v-row class="justify-center align-center mx-1 my-1">
            <v-icon class="mx-2">mdi-credit-card-outline</v-icon>
            <h3 class="text-center py-2">PAYMENT</h3>
          </v-row>
          <v-row class="justify-end align-center mt-2 mb-4 mx-1">
            <h4 class="mr-2">Equip<span class="d-none d-xl-inline">ment</span>:</h4>
            <v-chip color="primary" outlined>
              <h3>
                {{ odData.leaseEquipmentPayment | currency }}
              </h3>
            </v-chip>
          </v-row>
          <v-row class="justify-end align-center mt-3 mb-4 mx-1">
            <h4 class="mr-2">Service:</h4>
            <v-chip color="warning" outlined>
              <h3>
                {{ odData.totalServicePayment | currency }}
              </h3>
            </v-chip>
          </v-row>
          <v-row class="justify-end align-center mt-3 mb-2 mx-1">
            <h4 class="mr-2">Total:</h4>
            <v-chip color="success">
              <h3>
                {{ odData.leaseTotalPayment | currency }}
              </h3>
            </v-chip>
          </v-row>
        </v-card>
      </v-col>
      <!-- Service Info -->
      <v-col  cols="12" sm="12" md="8" lg="4" xl="3" class="d-flex flex-column">
        <v-card class="px-4 flex d-flex flex-column" outlined>
          <v-row class="justify-center align-center mt-2 mb-2">
            <v-icon class="mx-2">mdi-calendar-refresh-outline</v-icon>
            <h3 class="text-center py-2">BILLING</h3>
          </v-row>
          <v-row class="justify-end align-center mt-2 mb-4 mx-1">
            <h4 class="mr-2">Base:</h4>
            <v-chip color="primary" outlined>
              <h3>
                {{ odData.baseFreq.cycle !== null ? `${ odData.baseFreq.cycle}` : 'Not Selected'  }}
              </h3>
            </v-chip>
          </v-row>
          <v-row class="justify-end align-center mt-2 mb-4 mx-1">
            <h4 class="mr-2">Meters:</h4>
            <v-chip color="warning" outlined>
              <h3>
                {{ odData.meterFreq.cycle !== null ? `${ odData.meterFreq.cycle}` : 'Not Selected'   }}
              </h3>
            </v-chip>
          </v-row>
          <v-row class="justify-end align-center mt-2 mb-2 mx-1">
            <h4 class="mr-2">
              <span class="d-none d-md-inline">First </span>Read:
            </h4>
            <v-chip color="success">
              <h4 class="d-md-none">
                {{ getFirstReadDate }}
              </h4>
              <h3 class="d-none d-md-inline">
                {{ getFirstReadDate }}
              </h3>
            </v-chip>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
     <v-row class="mt-12 mb-5">
          <v-col cols="12" lg="2" class="text-center">
            <v-row class="justify-center align-center">
              <h4 class="mr-2">Term:</h4>
              <v-chip color="primary" outlined>
                <h3>{{ odData.leaseTerm }} Months</h3>
              </v-chip>
            </v-row>
          </v-col>       
          <v-col cols="12" lg="4" class="text-center">
            <v-row class="justify-center align-center">
              <h4 class="mr-2">D &amp; I:</h4>
              <v-chip color="primary" outlined>
                <h3>
                  {{ equipData.totalDelivery | currency }} + {{ equipData.totalInstall | currency }} = {{ equipData.totalDelivery + equipData.totalInstall | currency }} 
                </h3>
              </v-chip>
            </v-row>
          </v-col>       
          <v-col cols="12" lg="3" class="text-center">
            <v-row class="justify-center align-center">
              <h4 class="mr-2">Returns:</h4>
              <v-chip color="primary" outlined>
                <h3>
                  {{ equipData.totalReturns | currency }}
                </h3>
              </v-chip>
            </v-row>
          </v-col>
          <v-col cols="12" lg="3" class="text-center">
            <v-row class="justify-center align-center">
              <h4 class="mr-2">Doc Fee:</h4>
              <v-chip color="error" outlined>
                <h3 v-if="cv.leaseSelect.docFee">
                  {{ cv.leaseSelect.docFee | currency }}
                </h3>
                <h3 v-if="!cv.leaseSelect.docFee">
                  SET VENDOR
                </h3>
              </v-chip>
            </v-row>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { format, addMonths } from 'date-fns';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      finPanels: [0],
      rules: {
        required: (value) => !!value || 'This field is required.',
        minRate: (value) =>
          (!!value && value > 0) || 'Lease rate must be greater than zero.',
        leaseRate: (value) =>
          (!!value && /^\d+\.\d{1,6}$/.test(value)) ||
          'Enter valid lease rate (Ex. 0.02348)',
        dollarValue: (value) =>
          (!!value &&
            /^(?!0|\.00)[0-9]+(,\d{3})*?(\.[0-9][0-9]?)?$/.test(value)) ||
          'Invalid Amount',
      },
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return format(new Date(date), 'MM/dd/yyyy');
    },
    fullDate(date) {
      if (!date) return null;
      return format(new Date(date), 'MMM d yyyy');
    },
    toggleAll() {
      if (this.finPanels.length < 4) {
        this.finPanels = [0, 1, 2, 3];
      } else {
        this.finPanels = [];
      }
    },
  },
  computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues,
    }),
    getFirstReadDate() {
      const nextReadDate = addMonths(
        this.cv.startDate,
        this.odData.meterFreq.months
      );

      return format(nextReadDate, 'MMM d, yyyy');
    },
  },
};
</script>
