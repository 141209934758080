<template>
  <v-row justify="space-around" class="my-1">
    <v-col cols="4" sm="4" md="3" lg="2" xl="2" align-self-center class="text-center">
      <v-row align-self-center>
        <h3>
          <EditUnitPopup :equipId="equip.id" />
      {{ equip.model }}
        </h3>
      </v-row>
    </v-col>
    <v-col
      cols="4"
      sm="4"
      md="3"
      lg="2"
      xl="2"
      align-self-center
      class="text-right d-none d-lg-inline"
    >
      <h3>{{ equip.unitTotalMSRP | currency }}</h3>
    </v-col>
    <v-col
      cols="4"
      sm="4"
      md="3"
      lg="2"
      xl="2"
      align-self-center
      class="text-right d-none d-lg-inline"
    >
      <h3>{{ (equip.valuation / equip.unitTotalMSRP) | percent }}</h3>
    </v-col>
    <v-col
      cols="4"
      sm="4"
      md="3"
      lg="2"
      xl="2"
      align-self-center
      class="text-end"
    >
      <h3>{{ equip.valuation | currency }}</h3>
    </v-col>
    <v-col
      cols="4"
      sm="4"
      md="3"
      lg="2"
      xl="2"
      align-self-center
      class="text-end"
    >
      <h3>{{ equip.valPercent | percent }}</h3>
    </v-col>
  </v-row>

</template>

<script>
import { mapState } from "vuex";
import EditUnitPopup from "@/components/fds/fdsparts/financials/EditUnitPopup.vue"

export default {
  props: {
    equip: {
      type: Object,
      required: true,
    },
  },
  components: {
    EditUnitPopup
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required Field",
        minRate: (value) => (!!value && value >= 0) || "Zero or greater",
        dollarValue: (value) =>
          (!!value &&
            /^(?!0|\.00)[0-9]+(,\d{3})*?(\.[0-9][0-9]?)?$/.test(value)) ||
          "Invalid Amount",
      },
      manufacturers: [
        { name: "Sharp Electronics, Inc.", shortCode: "SHARP" },
        { name: "Kyocera", shortCode: "KYOCERA" },
        { name: "Lexmark", shortCode: "LEXMARK" },
        { name: "Hewlett Packard", shortCode: "HP" },
        { name: "Ricoh", shortCode: "RICOH" },
        { name: "Xerox", shortCode: "XEROX" },
        { name: "Kip", shortCode: "KIP" },
      ],
      moneyoptions: {
        locale: "en-US",
        prefix: "",
        suffix: "$",
        length: 11,
        precision: 2,
      },
      moneyprops: {
        reverse: "reverse",
      },
    };
  },
  methods: {
     // None
  },
  computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues,
    }),
  }
};
</script>
