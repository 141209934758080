<template>
  <v-container>
    <v-row justify="space-around" class="primary--text mb-1">
      <v-col cols="4" sm="4" md="3" lg="2" xl="2" class="text-center">
        <h4>BASE MODEL</h4>
      </v-col>
      <v-col cols="4" sm="4" md="3" lg="2" xl="2" class="text-end primary--text d-none d-lg-inline">
        <h4>TOTAL MSRP</h4>
      </v-col>
      <v-col cols="4" sm="4" md="3" lg="2" xl="2" class="text-end primary--text d-none d-lg-inline">
        <h4>MSRP LTV %</h4>
      </v-col>      
      <v-col cols="4" sm="4" md="3" lg="2" xl="2" class="success--text text-end">
        <h4>VALUATION</h4>
      </v-col>      
      <v-col cols="4" sm="4" md="3" lg="2" xl="2" class="success--text text-end">
        <h4>VAL %</h4>
      </v-col>
    </v-row>

    <div v-for="(equip, idx) in equipData.equipment" :key="idx">
      <IndividualUnit :equip="equip"/>
    </div>
      <TotalValuations removeUnit />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import IndividualUnit from '@/components/fds/fdsparts/financials/IndividualUnit.vue';
import TotalValuations from '@/components/fds/fdsparts/financials/TotalValuations.vue';

export default {
  components: {
    IndividualUnit,
    TotalValuations
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'This field is required.',
        minRate: (value) =>
          (!!value && value > 0) || 'Lease rate must be greater than zero.',
        leaseRate: (value) =>
          (!!value && /^\d+\.\d{1,6}$/.test(value)) ||
          'Enter valid lease rate (Ex. 0.02348)',
        dollarValue: (value) =>
          (!!value &&
            /^(?!0|\.00)[0-9]+(,\d{3})*?(\.[0-9][0-9]?)?$/.test(value)) ||
          'Invalid Amount',
      },
    };
  },
  methods: {
      ...mapActions('computed', ['setcomputedValues'])
  },
  computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues
    })
  }
};
</script>
