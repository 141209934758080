import store from '@/store/index.js'
import { differenceInCalendarDays } from "date-fns";

// Processes transitional billing charges based on 
// Lease Vendor selected and monthly payment data
export default () => {

    let daysDiff = differenceInCalendarDays(
        new Date(store.state.computed.computedValues.startDate),
        new Date(store.state.computed.computedValues.installDate)
    )

    if (daysDiff > 0 ) {

    let equipmentPayment =
        (store.state.orderdetails.odData.leaseEquipmentPayment / 30) * daysDiff;
      let servicePayment =
        (store.state.orderdetails.odData.totalServicePayment / 30) * daysDiff;
      let customerPayment = equipmentPayment + servicePayment;
      let dueUs = (equipmentPayment * store.state.computed.computedValues.leaseSelect.transEquip ) 
            + (servicePayment * store.state.computed.computedValues.leaseSelect.transMaint);

      let dueDealer = (Math.round(dueUs * 100) / 100).toFixed(2);
      let transEquip = (Math.round(equipmentPayment * 0.5 * 100) / 100).toFixed(2);
      let transMaint = (Math.round(servicePayment * 100) / 100).toFixed(2);
      let transCust = (Math.round(customerPayment * 100) / 100).toFixed(2);

      // Update State
      store.dispatch('computed/setcomputedValues', { 
          ...store.state.computed.computedValues, 
           daysDiff, 
           dueDealer,
           transEquip,
           transMaint,
           transCust
        }) 
    
        
    }

}