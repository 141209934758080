import store from '@/store/index.js';
import { addMonths } from 'date-fns';

export default () => {
  let odData = store.state.orderdetails.odData;
  let cv = store.state.computed.computedValues;
  let equipData = store.state.equipment.eqData;

  let escalateIntoRenewals = cv.escalateRenewals;

  let totalServicePayment = odData.totalServicePayment;
  // let equipmentPayment = odData.leaseEquipmentPayment
  let leaseTerm = odData.leaseTerm
  let totalMainframes = equipData.totalMainframes;
  let supplyShipping = 0;

  let escalationRate = ((parseInt(cv.escalationRate) + 100) / 100);
  let startDate = cv.startDate;

  let bwOverageRate = 0.0097
  let colorOverageRate = 0.0448

  let monoRates = [];
  let colorRates = [];

  let monoRate = {
    year: 1,
    startMonth: 1,
    endMonth: 12,
    totalServicePayment,
    overageRate: bwOverageRate,
    startDate,
  };

  let colorRate = {
    year: 1,
    startMonth: 1,
    endMonth: 12,
    totalServicePayment: 0,
    overageRate: colorOverageRate,
    startDate,
  };

  if (cv.escalateService) {
    for (let x=1; x < 11; x++) {

      if (escalateIntoRenewals) {
        if (x === 1) {
          monoRates.push(monoRate);
          colorRates.push(colorRate);
        } else {
          let year = x;
          let endMonth = x * 12;
          let startMonth = endMonth - 11;
            
        //   console.log('Rate: ' + monoRates[x-2].totalServicePayment);
        //   console.log('Escalate: ' + escalationRate);
        //   console.log('Computed: ' + monoRates[x-2].totalServicePayment * escalationRate);

          totalServicePayment = monoRates[x-2].totalServicePayment * escalationRate
          bwOverageRate = parseFloat((monoRates[x-2].overageRate * escalationRate))
          colorOverageRate = parseFloat((colorRates[x-2].overageRate * escalationRate))

          monoRate = {
            year,
            startMonth,
            endMonth,
            totalServicePayment,
            overageRate: bwOverageRate,
            startDate: addMonths(startDate, startMonth-1),
          };

          colorRate = {
            year,
            startMonth,
            endMonth,
            totalServicePayment: 0,
            overageRate: colorOverageRate,
            startDate: addMonths(startDate, startMonth-1),
          };

          monoRates.push(monoRate);
          colorRates.push(colorRate);
        }
      } else { // No escalations into renewals

        if (x === 1) {
          monoRates.push(monoRate);
          colorRates.push(colorRate);
        } else {
          
          let year = x;
          let endMonth = x * 12;
          let startMonth = endMonth - 11;
            
          //console.log('Rate: ' + monoRates[x-2].totalServicePayment);
          // console.log('Escalate: ' + escalationRate);
          // console.log('Computed: ' + monoRates[x-2].totalServicePayment * escalationRate);

          if (x <= (Math.ceil(leaseTerm / 12))) {
            totalServicePayment = monoRates[x-2].totalServicePayment * escalationRate
            bwOverageRate = parseFloat((monoRates[x-2].overageRate * escalationRate))
            colorOverageRate = parseFloat((colorRates[x-2].overageRate * escalationRate))
          } else {
            totalServicePayment = null
            bwOverageRate = null
            colorOverageRate = null

          }

 

          monoRate = {
            year,
            startMonth,
            endMonth,
            totalServicePayment,
            overageRate: bwOverageRate,
            startDate: addMonths(startDate, startMonth-1),
          };

          colorRate = {
            year,
            startMonth,
            endMonth,
            totalServicePayment: 0,
            overageRate: colorOverageRate,
            startDate: addMonths(startDate, startMonth-1),
          };

          if (totalServicePayment !== null) {
            monoRates.push(monoRate);
            colorRates.push(colorRate);
          }


        }
      }
    }
  } else {
    // No service escalations

    for (let x=1; (x < 11); x++) {

      if (x === 1) {
        monoRates.push(monoRate);
        colorRates.push(colorRate);
      } else {
        let year = x;
        let endMonth = x * 12;
        let startMonth = endMonth - 11;
        monoRate = {
          year,
          startMonth,
          endMonth,
          totalServicePayment,
          overageRate: bwOverageRate,
          startDate: addMonths(startDate, startMonth-1),
        };

        colorRate = {
          year,
          startMonth,
          endMonth,
          totalServicePayment: 0,
          overageRate: colorOverageRate,
          startDate: addMonths(startDate, startMonth-1),
        };

        monoRates.push(monoRate);
        colorRates.push(colorRate);
      }
    }
  }

  // Set supply shipping
  supplyShipping = totalMainframes * 3.00

  if (supplyShipping > 25.00 ) {
    supplyShipping = 25.00
  }

  store.dispatch('computed/setcomputedValues', { ...cv, supplyShipping });
  store.dispatch('maint/setserviceValues', { mono: monoRates, color: colorRates });
  
};
