<template>
  <v-expansion-panels v-model="demoPanels" multiple>
    <v-expansion-panel :key="0">
      <v-expansion-panel-header>
        <h3>
          <span class="d-none d-sm-inline">FINANCIALS</span>
          <span class="d-none d-md-inline text-truncate">
            | {{ odData.companyName }}</span
          >
        </h3>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-10"
          max-width="140px"
          small
          color="error"
          @click="resetFile"
        >
          <v-icon class="mr-2">mdi-cancel</v-icon> Start Over
        </v-btn>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12">
            <h4 class="d-md-none">
              {{ odData.companyName }}
            </h4>
          </v-col>
          <v-col cols="12" md="6">
            <h4>
              {{
                odData.quoteStatus
                  ? `${odData.quoteNumber} - ${odData.quoteStatus} | `
                  : `${odData.quoteNumber} | `
              }}
              {{
                odData.salesCode
                  ? `${odData.salesPro} - ${odData.salesCode}`
                  : `${odData.salesPro}`
              }}
              {{ odData.sapId ? ` | ${odData.sapId}` : `` }}
            </h4>
          </v-col>
        </v-row>
        <!-- LEASING DETAILS -->
        <!-- Leasing Company -->
        <v-row justify="space-around" align="stretch">
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="4"
            xl="3"
            class="d-flex flex-column"
          >
            <v-card class="px-3 flex d-flex flex-column" outlined>
              <v-row class="justify-center align-center mx-1 my-1">
                <v-icon class="mx-2">mdi-file-document-edit-outline</v-icon>
                <h3 class="text-center py-2">
                  LEASE
                  <span class="primary--text">{{
                    ` - ${odData.leaseTerm} Months`
                  }}</span>
                </h3>
              </v-row>
              <v-select
                class="px-4 mb-2"
                v-model="cv.leaseSelect"
                :items="leasingCompanies"
                :hint="`${cv.leaseSelect.shortCode}`"
                @change="leaseCoSelected"
                persistent-hint
                item-text="company"
                item-value="shortCode"
                label="Select Leasing Vendor"
                return-object
                single-line
              ></v-select>

              <!-- Lease Rate -->
              <v-row justify="space-around">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="leaseRate"
                    :rules="[rules.required, rules.leaseRate, rules.minRate]"
                    hint="Enter Internal Lease Rate"
                    placeholder="Lease Rate (Ex. 0.02348)"
                    label="Lease Rate"
                    reverse
                    @keydown.enter="leaseRateChanged"
                    @blur="leaseRateChanged"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="commPercent"
                    append-icon="mdi-percent-outline"
                    :rules="[]"
                    hint="Optional %"
                    placeholder="Percent (Ex. 3)"
                    label="Commission %"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-around" class="mx-2">
                <v-chip-group column mandatory v-model="cv.leaseType">
                  <v-chip value="FMV" outlined filter color="success"
                    >FMV</v-chip
                  >
                  <v-chip value="$1" outlined filter color="error">$1</v-chip>
                  <v-chip value="10%" outlined filter color="purple"
                    >10%</v-chip
                  >
                  <v-chip value="Other" outlined filter color="warning"
                    >Other</v-chip
                  >
                </v-chip-group>
              </v-row>
              <v-row justify="space-around" class="mx-2 mb-2">
                <!-- Install Date -->
                <v-col cols="12" md="6">
                  <h5>Installed</h5>
                  <DatePick
                    ref="installDate"
                    v-model="cv.installDate"
                    dateTitle="Installed: "
                    @datePicked="installDateChanged"
                  />
                </v-col>

                <!-- Contract Start Date -->
                <v-col cols="12" md="6">
                  <h5>Contract Start</h5>
                  <DatePick
                    v-model="cv.startDate"
                    dateTitle="Contract Start: "
                    :addedMonths="1"
                    firstDayofMonth
                    @datePicked="contractStartChanged"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- TRANSITIONAL BILLING -->
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="4"
            xl="3"
            class="d-flex flex-column"
          >
            <v-card class="px-3 flex d-flex flex-column" outlined>
              <v-row class="justify-center align-center mx-1 my-1">
                <v-icon class="mx-2">mdi-cash-multiple</v-icon>
                <h3 class="text-center py-2">
                  <span class="d-none d-sm-inline">TRANSITIONAL</span> BILLING
                </h3>
              </v-row>
              <v-col cols="12">
                <v-row align="center" justify="space-around">
                  <v-col cols="12" md="6">
                    <v-switch
                      inset
                      label="Include?"
                      color="success"
                      v-model="cv.transBill"
                      @click="toggleTransBill"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-chip color="primary" outlined v-if="cv.daysDiff > 0">
                      <h3>
                        {{ `${cv.daysDiff} DAYS` }}
                      </h3>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row
                  justify="space-around"
                  class="mx-2"
                  v-if="cv.leaseSelect.company"
                >
                  <v-col cols="12" md="6" v-if="cv.transBill">
                    <h5>Total Due</h5>
                    <v-chip color="primary" outlined>
                      <h4>{{ cv.dueDealer | currency }}</h4>
                    </v-chip>
                  </v-col>

                  <v-col cols="12" md="6" v-if="cv.transBill">
                    <h5>Client Total</h5>
                    <v-chip color="primary" outlined>
                      <h4>{{ cv.transCust | currency }}</h4>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row
                  justify="space-around"
                  class="mx-2"
                  v-if="cv.leaseSelect.company"
                >
                  <v-col cols="12" md="6" v-if="cv.transBill">
                    <h5>Equipment</h5>
                    <v-chip color="primary" outlined>
                      <h4>{{ cv.transEquip | currency }}</h4>
                    </v-chip>
                  </v-col>
                  <v-col cols="12" md="6" v-if="cv.transBill">
                    <h5>Service</h5>
                    <v-chip color="primary" outlined>
                      <h4>{{ cv.transMaint | currency }}</h4>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row
                  justify="space-around"
                  class="mx-2"
                  v-if="cv.leaseSelect.company"
                >
                  <v-col cols="12" md="6" v-if="cv.transBill">
                    <h5>Equipment %</h5>
                    <v-chip color="primary" outlined>
                      <h4>{{ cv.leaseSelect.transEquip * 100 }} %</h4>
                    </v-chip>
                  </v-col>
                  <v-col cols="12" md="6" v-if="cv.transBill">
                    <h5>Service %</h5>
                    <v-chip color="primary" outlined>
                      <h4>{{ cv.leaseSelect.transMaint * 100 }} %</h4>
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>

          <!-- SERVICE ESCALATIONS -->
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="4"
            xl="3"
            class="d-flex flex-column"
          >
            <v-card class="px-3 flex d-flex flex-column" outlined>
              <v-row class="justify-center align-center mx-1 my-1">
                <v-icon class="mx-2">mdi-stairs-up</v-icon>
                <h3 class="text-center py-2">
                  <span class="d-none d-sm-inline">SERVICE </span>ESCALATIONS
                </h3>
              </v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-switch
                      inset
                      label="Escalate?"
                      color="success"
                      v-model="cv.escalateService"
                      @click="toggleEscalation"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="4" v-if="cv.escalateService">
                    <v-text-field
                      v-model="cv.escalationRate"
                      :rules="[
                        rules.required,
                        rules.escalationRate,
                        rules.minEscalation,
                      ]"
                      hint="Percentage"
                      placeholder="10"
                      append-icon="mdi-percent-outline"
                      @blur="escalationRateChanged"
                      @keydown.enter="escalationRateChanged"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-col cols="12" v-if="cv.escalateService">
                  <v-switch
                    inset
                    label="Escalate Into Renewals?"
                    color="success"
                    v-model="cv.escalateRenewals"
                    @click="toggleIntoRenewal"
                  ></v-switch>
                </v-col>
                <v-row v-if="cv.escalateService">
                  <v-col cols="12" sm="7">
                    <v-switch
                      inset
                      label="Maintenance?"
                      color="success"
                      v-model="cv.serviceIncrease"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-switch
                      inset
                      label="Lease?"
                      color="success"
                      v-model="cv.leaseIncrease"
                    ></v-switch>
                  </v-col>
                  <!-- NOTES -->
                  <v-col cols="12">
                    <v-textarea
                      label="General Comments"
                      rows="1"
                      placeholder="Enter comments regarding this FDS"
                      v-model="cv.fdsNotes"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { format, parseISO, startOfMonth, addMonths } from 'date-fns';
import { mapState, mapActions } from 'vuex';
import DatePick from '@/components/fds/fdsparts/datePick/DatePick.vue';
import ValEquipment from '@/components/fds/getValuations.js';
import transitionalBilling from '@/components/fds/transitionalBilling.js';
import serviceBilling from '@/components/fds/getMaintenance.js';

export default {
  data: () => ({
    demoPanels: [0],
    leaseRate: null,
    commPercent: null,
    leasingCompanies: [
      {
        company: 'US Bank',
        shortCode: 'USB',
        transEquip: 0.5,
        transMaint: 1,
        docFee: -125.0,
      },
      {
        company: 'Wells Fargo Bank',
        shortCode: 'Wells',
        transEquip: 1,
        transMaint: 1,
        docFee: -125.0,
      },
      {
        company: 'GreatAmerica Financial',
        shortCode: 'Great Am',
        transEquip: 0.5,
        transMaint: 1,
        docFee: -125.0,
      },
      {
        company: 'De Lage Landen Finance',
        shortCode: 'DLL',
        transEquip: 0.5,
        transMaint: 1,
        docFee: -75.0,
      },
      {
        company: 'LEAF Commercial Finance',
        shortCode: 'LEAF',
        transEquip: 0.5,
        transMaint: 1,
        docFee: -125.0,
      },
      {
        company: 'CIT Commercial Finance',
        shortCode: 'CIT',
        transEquip: 0.5,
        transMaint: 1,
        docFee: -125.0,
      },
      {
        company: 'Sharp Leasing USA',
        shortCode: 'SLUSA',
        transEquip: 1,
        transMaint: 1,
        docFee: -125.0,
      },
      {
        company: 'Other',
        shortCode: 'Other',
        transEquip: 0.5,
        transMaint: 1,
        docFee: -125.0,
      },
    ],
    rules: {
      required: (value) => !!value || 'This field is required.',
      minRate: (value) =>
        (!!value && value > 0) || 'Lease rate must be greater than zero.',
      leaseRate: (value) =>
        (!!value && /^\d+\.\d{1,6}$/.test(value)) ||
        'Enter valid lease rate (Ex. 0.02348)',
      minEscalation: (value) =>
        (!!value && value > 0 && value <= 100) || 'Enter Rate 1 to 100.',
      escalationRate: (value) =>
        (!!value && /^\d{1,3}$/.test(value)) || 'Invalid Percentage',
      dollarValue: (value) =>
        (!!value &&
          /^(?!0|\.00)[0-9]+(,\d{3})*?(\.[0-9][0-9]?)?$/.test(value)) ||
        'Invalid Amount',
    },
  }),
  components: {
    DatePick,
  },
  methods: {
    ...mapActions('equipment', ['setEqData']),
    ...mapActions('orderdetails', ['setOdData']),
    ...mapActions('computed', ['setcomputedValues']),
    ...mapActions('maint', ['setserviceValues']),
    ...mapActions('snackbar', ['showSnack']),
    formatDate(date) {
      if (!date) return null;
      return format(new Date(date), 'MM/dd/yyyy');
    },
    fullDate(date) {
      if (!date) return null;
      return format(new Date(date), 'MMM dd, yyyy');
    },
    resetFile() {
      this.setEqData(null);
      this.setOdData(null);
      this.setcomputedValues(null);
      this.setserviceValues(null);
    },
    calcTransBill() {
      transitionalBilling();
    },
    toggleTransBill() {
      if (this.cv.transBill) {
        this.calcTransBill();
      } else {
        this.setcomputedValues({
          ...this.cv,
          daysDiff: 0,
          transCust: null,
          transEquip: null,
          transMaint: null,
          dueDealer: null,
        });
      }
    },
    updateCv() {
      this.setcomputedValues({ ...this.cv });
    },
    installDateChanged(newDate) {
      this.cv.installDate = parseISO(newDate);
      this.updateCv();
      this.calcTransBill();
    },
    contractStartChanged(newDate) {
      this.cv.startDate = parseISO(newDate);
      this.updateCv();
      this.calcTransBill();
    },
    leaseRateChanged() {
      if( this.leaseRate !== this.cv.leaseRate){
        this.cv.leaseRate = this.leaseRate;
        let totalFunding =
          Math.round(
            (this.odData.leaseEquipmentPayment / this.cv.leaseRate) * 100
          ) / 100;
        this.setcomputedValues({ ...this.cv, totalFunding });
        ValEquipment();
      }
    },
    leaseCoSelected: function() {
      this.setcomputedValues({ ...this.cv });
      this.calcTransBill();
    },
    toggleEscalation() {
      this.setcomputedValues({ ...this.cv });
      serviceBilling();
    },
    escalationRateChanged() {
      if (this.cv.escalationRate > 0) {
        this.setcomputedValues({ ...this.cv });
        serviceBilling();
      } else {
        this.showSnack({
          timeout: 5000,
          text: 'Escalation Rate Must be 1 or greater.',
          color: 'error',
        });
      }
    },
    toggleIntoRenewal() {
      this.setcomputedValues({ ...this.cv });
      serviceBilling();
    },
  },
  computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues,
    }),
  },
  created() {
    this.leaseRate = this.cv.leaseRate;
    let startDate = startOfMonth(this.cv.installDate);
    startDate = addMonths(startDate, 1);
    let cvs = { ...this.cv };
    this.setcomputedValues({ ...cvs, startDate });
    this.calcTransBill();
    serviceBilling();
  },
};
</script>
