<template>
  <v-card outlined class="mt-n8">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        fab
        dark
        x-small
        color="primary"
        title="Expand or Collapse All"
        @click="toggleAll"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels v-model="finPanels" multiple>
        <v-expansion-panel :key="0">
          <v-expansion-panel-header>
            <h3>
              <span class="d-none d-md-inline">Lease &amp; Service</span>
              Payment Details
            </h3>
            <v-spacer></v-spacer>
            <h4 class="font-weight-light d-none d-md-inline">
              <i>Customer Expects:</i> {{ odData.leaseRateAndOptions }}
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
                <PaymentDetails />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :key="1">
          <v-expansion-panel-header>
            <h3>Equipment MSRP &amp; Valuation</h3>

          </v-expansion-panel-header>
          <v-expansion-panel-content>           
            <v-spacer></v-spacer>
                <v-row justify="space-around" class="mx-5" align-content="center">
                    <v-col cols="4" xl="3" v-if="cv.addToMSRP > 0" title="Amount Needed in MSRP to Reach 125% LTV">
                      <v-chip outlined color="error">
                        <h3>+{{ cv.addToMSRP | currency }} MSRP for 125%</h3>
                      </v-chip>
                    </v-col>
                    <v-col cols="4" xl="3" :offset="cv.addToMSRP > 0 ? '' : '4'">
                      <v-chip outlined color="primary" title="Lease Vendor - Term - Lease Type">
                        <h3>{{ cv.leaseSelect.shortCode ? `${cv.leaseSelect.shortCode} - ` : ''}}{{ odData.leaseTerm }} Months - {{ cv.leaseType }}</h3>
                      </v-chip>
                    </v-col>
                    <v-col cols="4" lg="2" xl="1" class="d-none d-md-inline">
                    <v-text-field
                      v-model="leaseRate"
                      outlined
                      :rules="[rules.required, rules.leaseRate, rules.minRate]"
                      placeholder="Lease Rate (Ex. 0.02348)"
                      label="Lease Rate"
                      reverse
                      dense
                      title="Input Lease Rate and Hit Enter"
                      @keydown.enter="leaseRateChanged"
                    >
                    </v-text-field>
                    </v-col>
                    </v-row>
              <EquipValuations />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :key="2">
          <v-expansion-panel-header>
            <h3>Monochrome Service</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content> 
            <MaintenanceMatrix meterType="mono" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :key="3">
          <v-expansion-panel-header>
            <h3>Color Service</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content> 
            <MaintenanceMatrix meterType="color" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <div class="text-end mx-4 my-1">
    <span class="text-caption font-weight-thin">{{ equipData.equipVersion }} | {{ odData.odVersion }}</span>
  </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PaymentDetails from '@/components/fds/fdsparts/financials/PaymentDetails.vue'
import EquipValuations from '@/components/fds/fdsparts/financials/EquipValuations.vue'
import MaintenanceMatrix from '@/components/fds/fdsparts/maintenance/MaintenanceMatrix.vue'
import ValEquipment from '@/components/fds/getValuations.js'

export default {
  data() {
    return {
      finPanels: [0, 1, 2, 3],
      leaseRate: null,
      rules: {
        required: (value) => !!value || 'This field is required.',
        minRate: (value) =>
          (!!value && value > 0) || 'Lease rate must be greater than zero.',
        leaseRate: (value) =>
          (!!value && /^\d+\.\d{1,6}$/.test(value)) ||
          'Enter valid lease rate (Ex. 0.02348)',
      },      
     };
  },
  components: {
    PaymentDetails,
    EquipValuations,
    MaintenanceMatrix
  },
  methods: {
    ...mapActions('computed', ['setcomputedValues']), 
    toggleAll() {
      if (this.finPanels.length < 4) {
        this.finPanels = [0, 1, 2, 3];
      } else {
        this.finPanels = [];
      }
    },
    leaseRateChanged() {
      if (this.cv.leaseRate !== this.leaseRate) {
        let totalFunding =
          Math.round((this.odData.leaseEquipmentPayment / this.cv.leaseRate) * 100) / 100;
        this.setcomputedValues({
          ...this.cv,
          leaseRate: this.leaseRate,
          totalFunding,
        });

        ValEquipment();      
      }
    }
  },
  computed: {
    ...mapState({
      equipData: (state) => state.equipment.eqData,
      odData: (state) => state.orderdetails.odData,
      cv: (state) => state.computed.computedValues
    }),
  },
  created() {
    this.leaseRate = this.cv.leaseRate

  }
};
</script>
