var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-5 mb-3",attrs:{"justify":"space-around"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sv[_vm.meterType],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.startMonth",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startMonth)+"-"+_vm._s(item.endMonth)+" ")]}},{key:"item.totalServicePayment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalServicePayment))+" ")]}},{key:"item.overageRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.overageRate,'$', 6 ))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }